import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
// import AKLogos from "../components/AKLogos"
import {
  Container,
  Box,
  Flex,
  SimpleGrid
} from '@chakra-ui/react'
import { H1, H2, H3, H4 } from '../theme/Headings'
import { spacing, breakpoints, colors, hexToRGB } from '../theme/themeAlopex'
import ColorContext from '../context/ColorContext'
// Newsletter
import Newsletter from '../components/Newsletter'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// gsap
import {gsap} from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import AniLink from "gatsby-plugin-transition-link/AniLink"
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const CaseStudiesPage = ({ data }) => {
  // Case studies
  const { color } = useContext(ColorContext)
  const { css } = data

  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  const settings = {
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 4000,
    dots: false,
    arrows: false,
    infinite: true,
    adaptiveHeight: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: true,
    fade: true,
    pauseOnHover: false
  };

  return (
    <Layout>
      <SEO title="Our Work - Alopex" />
      {/* HEADING */}
      {/* <AKLogos /> */}
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        {/* <AKLogos /> */}
        <H1 variant="pageHeader" color={color.alpha}>Case Studies</H1>
        <H2 textAlign="center">
          We're proud of every project we do,
          <Box as="br" display={['none', null, 'block']} />
          but we're especially proud of these ones.
        </H2>
      </Container>

      {/* CASE STUDIES */}
      <Box backgroundColor="#FBFBFB" py={spacing.groups} mt={spacing.groups}>
        <Container maxWidth={breakpoints.xl}>
          {css.nodes.map((cs, i) => {
            return (
              <>
                <Flex mt={i > 0 ? spacing.groups : ''} flexDirection={['column', null, null, 'row']}>
                  <Box
                    className="slideContainer"
                    maxWidth={['100%', null, null, '45%']}
                    width="100%"
                    color="white"
                    sx={{
                      '&::after': {
                        background: `linear-gradient(to left, rgba(55, 71, 79, 0.9) 5%, transparent)`
                      }
                    }}
                  >
                    <Slider {...settings} style={{margin: '0 auto'}}>
                      {cs.csImages.map((img, i) => {
                        return (
                          <AniLink paintDrip hex={color.alpha} to={`/our-work/${cs.slug}`} style={{textDecoration: 'none'}}>
                            <img src={img.csImage.replace('/static', '')} alt={`work0${i}`} />
                          </AniLink>
                        )
                      })}
                    </Slider>
                  </Box>
                  <Box
                    flex="2 1 0%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    m={[`${spacing.elements} 0 0 0`, null, null, `0 0 0 ${spacing.elements}`]}
                    sx={{
                      'h3 a:hover': {
                        textDecoration: 'underline!important'
                      },
                      '.serviceLinks': {
                        color: 'rgba(0, 0, 0, 0.1)',
                        background: `-webkit-linear-gradient(right, ${color.alpha}, ${color.bravo})`,
                        WebkitBackgroundClip: 'text',
                        display: 'inline-block',
                        a: {
                          color: 'rgba(0, 0, 0, 0.2)',
                          '&:hover': {
                            color: 'rgba(0, 0, 0, 0.5)'
                          }
                        }
                      }
                    }}
                  >
                    <H3 mb={spacing.icon} lineHeight="0.85">
                      <AniLink paintDrip hex={color.alpha} to={`/our-work/${cs.slug}`} style={{textDecoration: 'none'}}>
                        {cs.title}
                      </AniLink>
                    </H3>
                    <Box as="p">
                      {cs.description}
                    </Box>
                    {cs.servicesLinks != null &&
                      <Box
                        as="p"
                        className="serviceLinks"
                      >
                        Services:&nbsp;
                        {cs.servicesLinks.map((csl, i) => {
                          return (
                            <>
                              <AniLink paintDrip hex={color.alpha} to={`/services/${csl.serviceLink}`}>
                                {`${csl.serviceLinkTitle}`}
                              </AniLink>
                              {i < cs.servicesLinks.length - 1 ? ', ' : ''}
                            </>
                          )
                        })}
                      </Box>
                    }
                    {/* Stat Boxes */}
                    {cs.csBadges != null &&
                      <SimpleGrid columns={[1, null, 2]} gap="10px" width="100%">
                        {cs.csBadges.map((csb, i) => {
                          return (
                            <Box border={`1px solid ${colors.brand.slate}`} padding="15px">
                              <H4 mb="0" lineHeight="1.1">{csb.csBadgeStat}</H4>
                              <Box as="p" mb="0">{csb.csBadgeDesciptor}</Box>
                            </Box>
                          )
                        })}
                      </SimpleGrid>
                    }
                  </Box>
                </Flex>
              </>
            )
          })}
        </Container>
      </Box>

      {/* Select clients */}
      {/* <Container maxWidth={breakpoints.xl}>
        <H2 textAlign="center" mb={spacing.elements}>Select Clients</H2>
        <Clients />
      </Container> */}
      <Newsletter title={data.contentJson.title} copy={data.contentJson.newsletterCopy} origin="case-studies" />
      <Footer mode="bg01" />
    </Layout>
  )
}

export const query = graphql`
  query aloStudiesNew {
    site {
      siteMetadata {
        title
      }
    }
    contentJson(slug: {eq: "case-studies"}) {
      title
      newsletterCopy
    }
    css: allOurWorkJson(limit: 100) {
      nodes {
        title
        slug
        servicesLinks {
          serviceLinkTitle
          serviceLink
        }
        csImages {
          csImage
        }
        csBadges {
          csBadgeStat
          csBadgeDesciptor
        }
        description
      }
    }
  }
`

export default CaseStudiesPage
